import type { FunctionComponent } from "react";
import PolicyPage from "./PolicyPage";

const termsAndConditionsContent = `
<div style="text-align: center;">
  <h2 style="font-weight: bold; text-decoration: underline;">Terms & Conditions</h2>
</div>

<p>The terms and conditions as set out herein (“Terms”) constitutes an agreement between the Company, and a natural or legal person who accesses and/or uses the Website in any manner (“you” or “your”).</p>

<p>The Website is a platform that facilitates online purchase by consumers of pharmaceutical products like prescription drugs, over the counter medicines etc., sold by PillSwift through its licensed premises (“Products”).</p>

<p>By using the Website, you expressly agree to be bound by the Terms. If you do not agree with any of the Terms, please refrain from using the Website. Please note that your usage of the Website, the Products, offers or promotions in relation to the Products and the Services (defined below) as may be provided by us or our affiliates or third parties, shall be governed by these Terms and such other terms that may be applicable (“Additional Terms”). If these Terms are inconsistent with any Additional Terms, the Additional Terms will supersede to the extent of the Additional Terms are applicable.</p>

<p>This document is an electronic record in terms of Information Technology Act, 2000 (“IT Act”), the applicable rules thereunder and the provisions pertaining to electronic records in various statutes as amended by the IT Act. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>

<p>Any individual who can enter into legally binding contracts as per Indian Contract Act, 1872, i.e. individuals who are 18 years of age or older, are of sound mind, and are not disqualified from entering into contracts by any law, can use and access the Website.</p>

<p><strong>THESE TERMS ARE DIVIDED INTO 2 (TWO) PARTS. PLEASE READ THE TERMS CAREFULLY.</strong></p>

<h3>PART A: TERMS FOR USAGE OF THE WEBSITE</h3>

<p><strong>1. GENERAL</strong></p>
<p>1.1 Your access, or otherwise any usage of the Website means you agree to these Terms read with all the policies that may be available on the Website (collectively, the “Agreement”).</p>

<p><strong>2. REGISTRATION OF YOUR ACCOUNT</strong></p>
<p>2.1 In order to access or use the Website you must set up an account by providing information about yourself as prompted on the sign-up page of the Website which may include your name, gender, mobile number and such other details relevant for placing orders through your account. Your login ID and password will be created based on the information provided by you, which you can use to access your PillSwift account at any time. Your credentials shared by you at the time of registration and your PillSwift login ID and password are referred to as “Account Information”.</p>
<p>2.2 You are solely authorized to operate the account created by you. Consequently, it is your responsibility to maintain the confidentiality of your Account Information. You shall remain solely responsible for all the actions by you on your account.</p>
<p>2.3 You will: (a) immediately inform us of any unauthorized use or security breach of your account, and (b) ensure that you log out of your account at the end of each session. You may be held responsible for any losses incurred to PillSwift or any other user due to unauthorized use of their account, resulting from your failure to keep your Account Information safe, secure and confidential. We reserve the right to refuse access to the Website, terminate or suspend accounts, remove or edit content at any time without providing any notice to you.</p>
<p>2.4 We constantly monitor the user’s account in order to avoid fraudulent accounts and transactions. Users with more than one account or availing referral vouchers fraudulently shall be liable for legal actions under the applicable law and we reserve the right to recover the cost of Products, transaction fees, or any other related cost including attorney fees, from such person using the Website fraudulently. In an event we detect any fraudulent or failed transaction, prior to initiation of legal action, we reserve the right to suspend or delete such an account with immediate effect and dishonour all past and pending orders without any liability on our part. However, this shall not absolve the User from any liability.</p>

<p><strong>3. COMMUNICATION AND UNSUBSCRIPTION</strong></p>
<p>3.1 You understand that once you have shared your Account Information and successfully registered on the Website, you may receive SMS or email notifications from PillSwift relating to your registration and transactions on the Website.</p>
<p>3.2 You consent to receive communications, notifications and commercial messages regarding any transaction / marketing / promotional activities that are related to your transaction on the website or the Products and Services that maybe available on the Website from time to time. By sharing your Account Information and registering on the Website and/ or verifying your contact number with us, you explicitly consent to receive marketing / promotional communications (through call, SMS, email or other digital and electronic means) from us and/or our authorized representatives regarding any new services or offerings.</p>
<p>3.3 While the Company endeavours to provide these notifications and reminders to you promptly, the Company does not provide any guarantee and will not be held liable or responsible for any failure to send such notifications/reminders to you.</p>
<p>3.4 You can unsubscribe/opt-out from receiving marketing/promotional communications, newsletters and other notifications from the Company at any time by following the instructions set out in such communications.</p>

<p><strong>4. USER WARRANTIES AND RESTRICTIONS</strong></p>
<p>4.1 You represent and warrant that: (a) your use of the Website will not violate any applicable law or regulation; (b) all information that is submitted to the Company in connection with the Website is true, accurate and lawful; (c) Your use of the Website does not breach any applicable Website policies or guidelines and will not cause injury to any person or entity. If at any time, the information provided by you is found to be false or inaccurate the Company will have the right to reject registration, cancel any or all orders, and restrict you from using the Website and other affiliated services in the future without any prior intimation whatsoever. You agree to indemnify the Company and its affiliates for all claims brought by a third party.</p>
<p>4.2 You will use the Website for a lawful purpose only, and will not undertake any activity that is harmful to the Company or the Website or its content or otherwise not envisaged through the Website. You have a limited licence to access and use the Website, solely for the purpose of availing the services, subject to these Terms.</p>
<p>4.3 The use of the Website by you is restricted to personal use only, and you will not use any portion of this Website (including the contents on the Platform) or Services for any resale or commercial purpose.</p>
<p>4.4 You will not do any of the following:</p>
<ul>
  <li>4.4.1 Use any engine, software, tool, agent or other mechanism (such as spiders, robots, avatars, worms, time bombs etc.) to navigate or search the Website.</li>
  <li>4.4.2 Make false or malicious statements against the Product, the Website or the Company.</li>
  <li>4.4.3 Post, copy, submit, upload, distribute, or otherwise transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise disrupt or damage the Website or any connected network, or otherwise interfere with any person or entity's use or enjoyment of the Website.</li>
  <li>4.4.4 Introduce any trojans, viruses, any other malicious software, any bots or scrape the Website for any information;</li>
  <li>4.4.5 Probe, scan, or test the vulnerability of any system, security or authentication measures implemented by us or otherwise tamper or attempt to tamper with our technological design and architecture.</li>
  <li>4.4.6 Hack into or introduce malicious software of any kind onto the Website.</li>
  <li>4.4.7 Engage in any form of antisocial, disruptive, or destructive acts, including “flaming,” “spamming,” “flooding,” “trolling,”, “phishing” and “griefing” as those terms are commonly understood and used on the internet.</li>
</ul>

<p>4.5 You are prohibited from hosting, displaying, uploading, modifying, publishing, transmitting, updating or sharing on or through the Website, any information that:</p>
<ul>
  <li>4.5.1 belongs to another person and to which You do not have any right</li>
  <li>4.5.2 is harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, invasive of another’s privacy, hateful, relating to or encouraging money laundering or gambling, or otherwise harmful in any manner whatsoever;</li>
  <li>4.5.3 harms minors in any way;</li>
  <li>4.5.4 infringes any patent, trademark, copyright or other proprietary rights;</li>
  <li>4.5.5 violates any law for the time being in force;</li>
  <li>4.5.6 deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
  <li>4.5.7 impersonates or defames another person; or</li>
  <li>4.5.8 contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource.</li>
</ul>

<h3>5. ACCESS OUTSIDE THE REPUBLIC OF INDIA</h3>

<p>5.1 The Company makes no representation that the content on the Website is appropriate to be used or accessed outside the Republic of India. Any user who use or accesses the Website from outside the Republic of India, do so at their own risk and are responsible for compliance with the laws of such jurisdiction. These Terms do not constitute, nor may these Terms be used for or in connection with any promotional activities or solicitation by anyone in any jurisdiction in which such promotional activities or solicitation are not authorized or to any person to whom it is unlawful to promote or solicit.</p>

<h3>6. DISCLAIMER</h3>

<p><strong>PRODUCTS</strong></p>
<p>6.1 The Company is solely responsible for providing you with an e-commerce website to purchase Product or avail third party services, if you deem fit.</p>
<p>6.2 It is hereby clarified that that the manufacturers themselves are responsible for all compliances relating to the manufacturing of the Products including any labelling, mandatory declaration requirements, etc. under the applicable law. The manufacturers are liable to be compliant under the relevant provisions of applicable laws, including but not limited to the Legal Metrology Act, Drugs and Cosmetics Act, Food Safety and Standards Act, Drugs and Magic Remedies Act etc. and the rules made thereunder.</p>
<p>6.3 As a means to assist the users in identifying the Products of their choice, to the extent permitted by applicable law, we provide visual representations on the Website including graphics, illustrations, photographs, images, videos, charts, screenshots, infographics and other visual aids. While reasonable efforts are made to provide accurate visual representation, we disclaim any guarantee or warranty of accuracy of such visual representation or description of the Products.</p>
<p>6.4 You understand that the Website may provide third-party services to help you locate and make appointments for ordering medicines consultation with registered medical practitioners and/or diagnostics service centres. Please note that this service is provided by a third-party, and the Website does not guarantee nor make any representation with respect to the correctness, completeness or accuracy of such services. You should seek emergency help or follow-up care when required and continue to consult your primary health care provider as you may deem fit.</p>
<p>6.5 All communication between you and the third party service provider is a separate transaction between you and such third-parties. Any liability or claim arising out of such third party services availed by you shall be between you and such third party. The Company disclaims any liability that may arise out of your communication, availment of services (including but not limited to deficiency, shortcoming, and inaccuracy) with such third parties.</p>
<p>6.6 The products and the third party services are provided to you on an “as is” basis. We do not make any representations or warranties on behalf of the products and third party services. The company does not covenant or provide any representations and warranties in respect of quality, suitability, accuracy, reliability, performance, safety, merchantability, fitness for a particular purpose/consumption or the content (including product or pricing information and/or specifications) on the Website and that the services will be made available at all times.</p>

<h3>7. PRESCRIPTION MEDICATION POLICY</h3>

<p>7.1 The Website allows you to purchase the Products (prescription medicine) against a valid medical prescription issued by a medical expert/ doctor to be provided to a registered pharmacist for the purpose of dispensing such medicine (“Prescription Drugs”), offered for sale on the Website. In order to purchase Prescription Drugs from the Website, You are required to upload a scanned copy of the valid prescription on the Website or email the same to the Company. The order would not be processed by the Company until it receives a copy of a valid prescription. The Company will verify the prescription forwarded by You and in case we observe any discrepancy in the prescription uploaded by You, we will cancel the order immediately.</p>

<h3>8. CONTENT ON THE WEBSITE</h3>

<p>8.1 All information, content, material, text, graphics, images, logos, button icons, software code, interface, design and the collection, arrangement and assembly of the content on the Website are the property of the Company or its affiliates (“Company Content”), and are protected under copyright, trademark and other applicable laws. Further, all trademarks, services marks, trade names and trade secrets in relation to the Website whether or not displayed on the Website, are proprietary to the Company.</p>
<p>8.2 The Company may display on the Website any third-party logos, trade names, trademarks of other brands, as per the license granted to the Company by such brands.</p>
<p>8.3 No information, content or material from the Website (including the Company Content) may be copied, reproduced, republished, duplicated, copied, sold, resold, uploaded, posted, transmitted, distributed or otherwise exploited in any way (including for any commercial purpose) without Company’s express written permission. You will not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Company and its affiliates without express written consent. You may not use any meta tags or any other “hidden text” utilizing the Company’s or its affiliates’ names or trademarks without the express written consent of the Company and/or its affiliates, as applicable. Any unauthorized use terminates the permissions granted in these Terms.</p>

<h3>9. LICENSE AND USE OF YOUR CONTENT</h3>

<p>9.1 Your use of the Website, the Services, and access to the Company Content (as defined below) is subject to a limited, revocable and non-exclusive license which is granted to you when you register on the Website. You will use the Website solely for identifying Products, carrying out purchases of Products and processing returns and refunds, in accordance with Return and Refund Policy of the Website, for your personal use only and not for business purposes.</p>
<p>9.2 The license granted to you does not include a license for: (a) resale of Products or commercial use of the Website or the Company Content, (b) any collection and use of product listings, description, or prices, (c) any use of the Website, the Services and/or of the Company Content other than as contemplated in these Terms, (d) any downloading or copying of Account Information, (e) any use of data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) any parts of the Website, (f) creating and/ or publishing your own database that features parts of the Website.</p>
<p>9.3 You grant to the Company a royalty-free, perpetual, irrevocable, non-exclusive right and license to adopt, publish, reproduce, disseminate, transmit, distribute, copy, use, create derivative works from, display worldwide, or act on any material posted by you on the Website without additional approval or consideration in any form, media, or technology now known or later developed, for the full term of any rights that may exist in such content, and you waive any claim over all feedback, comments, ideas or suggestions or any other content provided through or on the Website. You agree to perform all further acts necessary to perfect any of the above rights granted by you to the Company, including the execution of deeds and documents, at its request.</p>

<h3>10. INDEMNIFICATION AND LIMITATION OF LIABILITY</h3>

<p>10.1 You agree to indemnify, defend and hold us harmless our affiliates, officers, directors, employees, consultants, licensors, agents, and representatives from and against any and all third- party claims, losses, liabilities, damages, and/or costs (including reasonable attorney fees and costs) arising from your access to or use of the Website, violation of these Terms, or infringement of any of our or any third-party intellectual property or other rights. We will notify you promptly of any such claim, loss, liability, or demand, and in addition to the foregoing, you agree to provide us with reasonable assistance, at your expense, in defending any such claim, loss, liability, damage, or cost.</p>

<h3>PART B – TERMS OF SALE ON THE WEBSITE</h3>

<p><strong>11. OUR CONTRACT OF SALE</strong></p>
<p>11.1 Listing and display of a Product by us on the Website is our invitation to you to make an offer for purchase of such Product. Likewise, the placement of an order on the Website by you is your offer to buy the Product(s) from us.</p>
<p>11.2 Once you have placed an order with us for purchase of a Product, you will receive an e-mail and/or mobile confirming receipt of your order and containing the details of your order (the “Order Confirmation”). The Order Confirmation is merely an acknowledgement that we have received your order and does not signify our acceptance of your offer.</p>
<p>11.3 We only accept your offer and conclude the contract of sale for a Products ordered by you, when the Product is shipped or dispatched to you and an e-mail confirmation and/or confirmation to your registered mobile number is sent stating that the Product has been shipped or dispatched (the “Shipment Confirmation”). We reserve the right, at our sole discretion, to refuse or cancel any order for any reason whatsoever before sending Shipment Confirmation to you.</p>
<p>11.4 If your order is dispatched in more than one package, you may receive separate Shipment Confirmations for each package, and each Shipment Confirmation and corresponding dispatch will conclude a separate contract of sale between you and us for the Product(s) specified in that Shipment Confirmation.</p>
<p>11.5 Any communication from us shall be sent only to your registered mobile number and/or email address or such other contact number or email address that you may designate, for any particular transaction. You shall be solely responsible to update your registered mobile number and/or email address on the Website in the event there is a change. Further, we may also send you notifications and reminders with respect to scheduled deliveries of the purchased Products. While we shall make every endeavour to share prompt reminders and notifications relating to the delivery of purchased Product(s) with you, we shall not be held liable for any failure to send such notifications or reminders to you.</p>
<p>11.6 Please note that we dispense Products only in quantities which correspond to requirement as per the prescription.</p>
<p>11.7 All orders placed on the Website are subject to the availability of such Products, our acceptance of your offer as per the above and your continued adherence to these Terms.</p>
<p>11.8 You hereby authorise us to declare and provide declarations to any governmental authority on request on your behalf, including that the Products ordered by you are for personal, non-commercial use.</p>

<h3>12. RETURN AND REFUND</h3>

<p>12.1 Please review the Cancellation, Returns and Refund Policy available on the Website, which applies to Products availed from us.</p>

<h3>13. PRODUCT AVAILABILITY</h3>

<p>13.1 We list availability information for the Products on the relevant webpage of the Website. Beyond what we say on that webpage or otherwise on the Website, we cannot be more specific about availability. Please note that dispatch estimate is not guaranteed and should not be relied upon as such. As we process your order, you will be informed by e-mail and/or mobile SMS if any Products you order turn out to be unavailable or out of stock.</p>

<h3>14. PRODUCT PRICING</h3>

<p>14.1 All prices are listed in Indian Rupees. Price, as displayed, is inclusive of all applicable taxes.</p>
<p>14.2 Products in your shopping cart of the Website will reflect the most recent price as displayed on the Product’s information webpage on the Website. Please note that this price may differ from the price shown for the Product when you first placed it in your shopping cart. Placing a Product in your shopping cart does not reserve the price shown at that time. It is also possible that a Product’s price may vary between the time you place it in your shopping cart and the time you place the order. The price as applicable at the time of placing order shall be final.</p>

<h3>15. LICENSES</h3>

<p>15.1 You acknowledge that there may be licenses/permissions required under the applicable laws to use, purchase or otherwise possess certain Product(s) by you. You will be solely responsible for obtaining such licenses/permissions and complying with the terms of such licenses/permissions.</p>

<h3>16. LIMITATION OF LIABILITY</h3>

<p>16.1 In no event, our aggregate liability, shall extend beyond the money charged from you for purchases made pursuant to an order under which such liability has arisen and been established. We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when a contract for the sale of Product by us to you was formed.</p>

<h3>17. MISCELLANEOUS</h3>

<p><strong>17.1 FORCE MAJEURE</strong></p>
<p>We will not be held responsible for any delay or failure to comply with its obligations if the delay or failure arises from any cause which is beyond our reasonable control.</p>

<p><strong>17.2 WAIVER</strong></p>
<p>No provision in these Terms will be deemed waived and no breach excused, unless such waiver or consent is in writing and signed by us. Any consent by us to, or waiver of your breach, whether expressed or implied, will not constitute consent to, waiver of, or excuse for any other different or subsequent breach.</p>

<p><strong>17.3 SEVERABILITY</strong></p>
<p>If any provision of these Terms is held by a court of competent jurisdiction to be unenforceable under applicable law, then such provision will be excluded from these Terms and the remainder of these Terms will be interpreted as if such provision were so excluded and will be enforceable in accordance with its terms; provided that, in such event, these Terms will be interpreted so as to give effect, to the greatest extent consistent with and permitted by applicable law, to the meaning and intention of the excluded provision as determined by such court of competent jurisdiction.</p>

<p><strong>17.4 AMENDMENT</strong></p>
<p>These Terms are subject to amendments and modifications and may be updated from time to time, without any advance notice. You are requested to regularly review the Terms as available on the Website. Your relationship with the Website will be governed by the most current version of these Terms, as published on the Website.</p>

<p><strong>17.5 OTHERS:</strong></p>
<ul>
  <li>17.5.1 In addition to these Terms, you will also ensure that you are in compliance with the Terms of the third parties, such as bank offers Terms, brand promotional offers, whose links, if any, are contained/embedded in the Services. You agree that we will not be liable for any transaction between yourself and any such third parties.</li>
  <li>17.5.2 These Terms supersede all previous oral and written Terms (if any) communicated to you by us, for the use of the Website, and the rights and liabilities with respect to any Services provided by us shall be limited to the scope of these Terms of Use.</li>
</ul>

<h3>18. GOVERNING LAW AND DISPUTE RESOLUTION</h3>

<p>18.1 All disputes and claims arising out of these Terms in relation to any or all transactions on the Website is governed by the laws of India and shall be subject to exclusive jurisdiction of the competent courts of Gwalior, Madhya Pradesh.</p>

<h3>19. GRIEVANCE REDRESSAL</h3>

<p><strong>19.1 Customer Support:</strong> You may contact the Customer Support on connect@pillswift.in Phone: 09039054361</p>
<p><strong>19.2 Grievance Officer:</strong></p>
<ul>
  <li>Name: Mr. Adarsh Kumar Tiwari</li>
  <li>Designation: Grievance Officer</li>
  <li>Email Address: adarsh.tiwari@pillswift.in</li>
</ul>
`;


const TermsAndConitionsPolicy: FunctionComponent = () => (
  <PolicyPage
    title="Terms & Conditions"
    content={termsAndConditionsContent}
  />
);

export default TermsAndConitionsPolicy;
